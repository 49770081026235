.ag-theme-alpine .ag-root .ag-cell {
  border-right: 1px dotted #d3d3d3 !important; /* Grey vertical lines */
}

.ag-theme-alpine .ag-header-cell {
  border-right: 1px solid #d3d3d3 !important;
}

.ag-center-aligned-header .ag-header-cell-label {
  justify-content: center !important;
  /* text-align: center; */
}

.left-aligned-header-padding .ag-header-cell-label {
  padding: 0 0 0 10px !important ;
}

.right-aligned-header-padding .ag-header-cell-label {
  padding: 0 10px 0 0 !important ;
}

.left-aligned-cell-padding {
  text-align: left !important;
  padding: 0 0 0 10px;
}

.right-aligned-cell-padding {
  text-align: right;
  padding: 0 10px 0 0;
}

/* .ag-header {
  background-color: rgb(
    243,
    244,
    246
  ) !important; 
  color: rgb(31, 41, 55) !important;
} */
