.custom-header-padding {
  padding: 0px !important; /* Adjust the value as needed */
  /* white-space: nowrap; */
}

.ag-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
}

/* Default font size */
.ag-theme-alpine,
.ag-theme-balham,
.ag-theme-material,
.ag-cell {
  font-size: 12px !important; /* Default size for larger screens */
}

/* Reduce font size for small screens */
@media screen and (max-width: 1024px) {
  .ag-theme-alpine,
  .ag-theme-balham,
  .ag-theme-material,
  .ag-cell {
    font-size: 10px !important;
  }
}

/* Further reduce font size for very small screens */
@media screen and (max-width: 768px) {
  .ag-theme-alpine,
  .ag-theme-balham,
  .ag-theme-material,
  .ag-cell {
    font-size: 8px !important;
  }
}

/* Default (Large Screens) */
.ag-theme-alpine .ag-header-cell-label {
  font-size: 14px; /* Default size */
}

/* Medium Screens (Tablets) */
@media (max-width: 1024px) {
  .ag-theme-alpine .ag-header-cell-label {
    font-size: 12px;
  }
}

/* Small Screens (Mobile) */
@media (max-width: 768px) {
  .ag-theme-alpine .ag-header-cell-label,
  .ag-header-cell {
    font-size: 10px;
    padding: 0;
  }
}

.dateHeader {
  min-width: 55px;
  padding: 0 0 0 10px;
}

.fitContentHeader {
  max-width: fit-content;
}

paddingZero {
  padding: 0;
}
