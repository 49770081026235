.option-container {
  position: relative;
  width: 100%;
  height: 65vh;
  display: flex;
  /*align-items: center;
      justify-content: center; */
  /* background-color: blue; */
}

.option-table {
  position: absolute; /* Ensure this is positioned relative to the container */
  z-index: 0;
  width: 100%;
  height: 100%;
}

.order-form {
  position: fixed;
  z-index: 1000;
  top: 0%;
  left: 0%;
}
