.modal-entry {
  animation: slide-in 0.5s ease-in;
}

.modal-exit {
  animation: slide-out 0.5s ease-in;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
