#optionChainTableContainer {
  display: flex;
  width: 100%;
  height: 82vh;
  max-height: 1000px;
  /* justify-content: center; */
  /* background-color: aqua; */
}

.header-call {
  background-color: rgb(255, 246, 246);
  color: rgb(229, 100, 72);
  justify-content: left;
}

.header-put {
  background-color: rgb(241, 251, 246);
  color: rgb(32, 159, 124);
  justify-content: right;
}

.header-blank {
  background-color: rgb(255, 255, 255);
  color: black;
}
.yellow-cell {
  background-color: rgb(243, 254, 184);
}

.header-white {
  background-color: white;
}

.header-blue {
  background-color: rgba(187, 233, 255, 0.2);
}
.header-call-oi .ag-header-cell-text {
  color: rgb(229, 100, 72) !important;
}
.header-call-oi {
  background-color: white;
  justify-content: right;
  text-align: right;
}

/* .right-aligned-cell {
    justify-content: right !important;
    text-align: right !important;
} */
.header-put-oi .ag-header-cell-text {
  color: rgb(32, 159, 124);
}
.header-put-oi {
  background-color: white;
}

/* .ag-cell , .ag-header-cell{
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
} */

.button-container {
  display: none; /* Initially hide the buttons */
  justify-content: space-around;
}

/* Blur effect for the entire row */
.ag-row-hover,
.ag-row-active {
  backdrop-filter: blur(5px);
}

.ag-row-active .value-column,
.ag-row-hover .value-column {
  display: none;
}
.value-column {
  display: inline-block;
}
/* Show the buttons when the row is hovered */
.ag-row-active .button-container,
.ag-row-hover .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.8); */
}

/* Styling for Buy and Sell buttons */
.buy-button,
.sell-button {
  margin: 10px 2px;
  padding: 0px 12px;
  background-color: #35794c;
  color: white;
  border: none;
  border-radius: 7px;
  cursor: pointer;
}

.sell-button {
  background-color: #dc3545;
}

/* Optional: Add some hover effects to the buttons */
.buy-button:hover,
.sell-button:hover {
  opacity: 0.8;
}
